// import * as React  from "react"
import React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/andre-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/andre"
import { Helmet } from "react-helmet"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">

          <b>Basic Concepts of Harmonic Patterns</b>
          <ul className="basic-list ml-14 mb-4">
            <li>
              Introduction to the harmonic patterns.
            </li>
          </ul>

          <b>Practical Recognition of Patterns</b>
          <ul className="basic-list ml-14 mb-4">
            <li>
              Identification and practical interpretation of harmonic patterns.
            </li>
          </ul>

          <b>In-depth Study of Harmonic Patterns</b>
          <ul className="basic-list ml-14 mb-4">
            <li>
              Advanced exploration of harmonic patterns and their practical application.
            </li>
          </ul>

          <b>Advanced Strategies with RSI and/or MACD </b>
          <ul className="basic-list ml-14 mb-4">
            <li>
              Advanced strategies using RSI and MACD in conjunction with harmonic patterns.
            </li>
          </ul>

          <b>Importance of Risk Management in Harmonic Trading</b>
          <ul className="basic-list ml-14 mb-4">
            <li>
              Fundamentals and practical application of risk management with a focus on harmonic patterns.
            </li>
          </ul>

          <b>Practical Application Exercises</b>
          <ul className="basic-list ml-14 mb-4">
            <li>
              Practical application of risk management strategies in real scenarios.
            </li>
          </ul>


        </div >
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Andre Cardoso" ,
    firstName: "Andre",
    twitter: "@Trading_Jazz",
    headerText: "Take your trading skills to the next level. Uncover the intricacies of Harmonic Trading!",
    videoUrlCode: "Hs-pA8ZN7Vw",
    lang: 'eng',
  }

  return (
    <>
      <main>
        <Seo title='Trader Coach Andre' />
        <Helmet
          link={[
            {
              rel: 'alternate',
              hreflang: 'en',
              href: 'https://trader-coach.com/andre/',
            },
            {
              rel: 'alternate',
              hreflang: 'pt',
              href: 'https://trader-coach.com/andre-prt/',
            }
          ]}
        />
        <GDPRConsent />
        <Header props={mentor} />
        <>
          {<DiscountBanners mentor='andre' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                    src={imgProfile}
                    alt={'Andre'}
                  />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-3xl font-bold">Global Market Analyst</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                With over 12 years of experience as a seasoned financial market analyst, specializing in harmonic patterns, Fibonacci analysis, and reversal patterns, André is here to guide you on your journey to becoming a more proficient trader.
                <br/><br/>
                With a wealth of knowledge accumulated over years of dedicated practice and a track record of success, André brings a unique blend of expertise and practical insights to his coaching sessions.
                <br/>
                <br/>His career highlights include:
                <br />
                <ul className="basic-list ml-14 mb-4">
                  <li>
                    <b>12 Years of Experience:</b> André boasts a rich background in financial market analysis.
                  </li>
                  <li>
                    <b>Specialized Expertise:</b> His specialization lies in identifying market patterns, conducting Fibonacci analysis, and recognizing reversal harmonic patterns, all of which are crucial elements of technical analysis.
                  </li>
                  <li>
                    <b>Dedicated Educator:</b> André is passionate about sharing his expertise with other traders, helping them navigate the complexities of the market with confidence.
                  </li>
                </ul>



              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $120</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $200.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=58719673"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden" id="andre">
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=58719673" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
                <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{name: mentor.firstName, twitter: mentor.twitter }} />

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
